<template lang="pug">
.details-page(:class="[lang]")
  .contain(:class="isTryMore ? 'pop-up':'take-back'")
    try-more()
  div.header
    div.logo_area(@click="backHome")
      div.img_area
        img(src="../../assets/img/m/logo.png")
      div.title Answered!
    .area-right
      div.lanSwitch
        el-dropdown(@command="handleCommand", trigger="click")
          span.el-dropdown-link {{command}}
              i.el-icon-arrow-down.el-icon--right
          el-dropdown-menu(slot="dropdown")
              el-dropdown-item(command="Eng") Eng
              el-dropdown-item(command="Indonesia") Indonesia
      .input-area(v-if="isShowInput")
        input.input(
          :placeholder="$TT('search')"
          maxlength='150'
          v-model="inputValue"
          autofocus="autofocus"
          @keyup.enter="unShowInput"
        )
        img.input-search_icon(src="../../assets/img/m/input-icon.png")
      .search-icon(@click="showInput" v-else)
        img(src="../../assets/img/m/search-icon.png")
  .section
    .problem-details
      .problem
        .point
        .title(v-html="$TT('Problem')")
        .problem-content#problems(v-html="questionDetail.content")
      .answer
        .point
        .title(v-html="$TT('Answer')")
        .answer-content
          .answer-head
            .answer-by(v-html="$TT('answered by') + `${questionDetail.username}`")
            .answer-text(v-html="$TT('Answer:')")
            .answer-result
              img(src="../../assets/img/mosaic.png")
          .answer-analysis
            .analysis-text(v-html="$TT('Explanation')")
            .analysis-content#analysis-content(v-html="questionDetail.analysis")
          .remove-mosaic(v-html="$TT('remove mosaic')" @click="tryMore")
    .problems-list
      .title(v-html="$TT('Similar problems')")
      .cut-line
      .no-problems(v-if="isNoProblems")
        .no-porblems_img
          img(src="../../assets/img/noProblems.png")
        .no-problems_text(v-html="$TT('noProblems')")
      .problems-content#similar-problems
        .problems-item(v-for='(item, index) in similarList' :key='index')
          .item_detail(v-html='item.question')
          .check-solution(v-html="$TT('Check solution')" @click="viewDetails(item, 'detailpage_similar_checksolution	')")
    .not-question
      .title(v-html="$TT('not your question')")
      .button-unlock_question(v-html="$TT('unlock question')" @click="unlockMore('detailpage_unlockmore_click')")
  div.footer(@click="download('detailpage_download_foot_click')")
    div.logo
      img(src="../../assets/img/m/footer_logo.png")
    div.title(v-html="$TT('mforfree')")
    div.google
      img(src="../../assets/img/m/google.png")
</template>
<script>
import service from '@/service/index.js'
import MathJax from '@/util/mathJax.js'
import tryMore from '@/components/mtryMore.vue'

export default {
  data() {
    return {
      lang:"en",
      command:'Eng',
      id: '',
      isShowInput: false,
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
      inputValue: '',
      similarList: [],
      questionDetail: {},
      isNoProblems: false,
    }
  },
  components: {tryMore,},
  created() {
    this.lang = this.$route.query.lang || 'en'
    this.$setLang(this.lang)
    window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
    window.gtag('event', 'detailpage_show')
  },
  mounted() {
    this.id = this.$route.query.id
    this.getQuestionDetail()
  },
  beforeRouteUpdate (to, from, next) {
    if(to.fullPath === from.fullPath) {
      next()
    } else {
      this.id = to.query.id
      this.lang = to.query.lang
      this.$setLang(this.lang)
      this.getQuestionDetail()
    }
    next()
  },
  computed: {
    // ...mapGetters(["isTryMore"]),
    isTryMore: {
      get() {
        return this.$store.state.isTryMore
      },
      set(newValue) {
        this.$store.state.isTryMore = newValue
      },
    },
  },
  watch:{
    command:function(){
        if(this.command == 'Eng' && this.lang !== 'en'){
          this.lang = 'en'
          this.$setLang(this.lang)
          this.switchLanguage()
        }else if(this.command == 'Indonesia' && this.lang !== 'id') {
          this.lang = 'id'
          this.$setLang(this.lang)
          this.switchLanguage()
        }
    },
    lang:function(){
      window._rlog.push(['_trackCustom', 'EVENT', [['lang', this.lang]]])
      window.gtag('event', 'language_switch')
      if(this.lang == 'en'){
          this.command = 'Eng'
      }else if(this.lang == "id"){
        this.command = "Indonesia"
      }
    },
    "$store.state.isTryMore"(val) {
      // console.log(val, 'val');
      // this.isTryMore = false
      this.isTryMore = val
    }
  },
  methods: {
    backHome() {
      this.$router.push({
        path: '/m',
        query: {
          lang: this.lang
        }
      })
    },
    formatMath() {
      let that = this;
      if(this.timeId) {
        clearTimeout(this.timeId);
      }
      this.timeId = setTimeout(function () {
        that.$nextTick(function () {
          if(MathJax.initMathjaxConfig()){//判断是否初始配置，若无则配置。
            MathJax.MathQueue('problems');
            MathJax.MathQueue('similar-problems');
            MathJax.MathQueue('analysis-content');
          }
        })
      },10);
    },
    tryMore() {
      this.$store.commit('isTryMore', true)
      window.gtag('event', 'detailpage_dialog_show')
    },
    unlockMore(eventType) {
      window.gtag('event', eventType)
      this.$store.commit('isTryMore', true)
      window.gtag('event', 'detailpage_dialog_show')
    },
    handleCommand(command){
      this.command = command
    },
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    showInput() {
      this.isShowInput = true
    },
    unShowInput() {
      if(this.inputValue === '') {
        this.isShowInput = false
      } else {
        this.$router.push({
          path: '/m/quesList',
          query: {
            lang: this.lang,
            inputValue: this.inputValue
          }
        })
      }
    },
    switchLanguage() {
      this.$router.push({
        path: '/m/quesDetails',
        query: {
          lang: this.lang,
          id: this.id
        }
      })
    },
    viewDetails(item, eventType) {
      window.gtag('event', eventType)
      this.$router.push({
        path: '/m/quesDetails',
        query: {
          lang: this.lang,
          id: item.id
        }
      })
    },
    getQuestionDetail() {
      const id = this.id
      service.getQuesDetails(id).then((res) => {
        this.questionDetail = res.data.question
        this.similarList = res.data.similar_questions
        if(this.similarList.length === 0) {
          this.isNoProblems = true
        } else {
          this.isNoProblems = false
        }
        this.formatMath();
        document.body.scrollTop = document.documentElement.scrollTop = 0
      })
    },
  }
}
</script>
<style lang="stylus" scoped>
.pop-up, .take-back
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  background rgba(0, 0, 0, 0.4)
  z-index 99
  transition 0.2s ease-out
.take-back
  background rgba(0, 0, 0, 0)
  z-index -99
.details-page
  font-family: Roboto;
.header
  display:flex
  height 1.44rem
  align-items:center
  padding: 0 0.4rem
  position:relative
  background #FFFFFF
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1)
  .logo_area
    display:flex
    align-items:center
    cursor pointer
    .img_area
      width:0.64rem
      height 0.64rem
      margin-right:0.16rem
      img
        width:100%
    .title
      font-weight: bold;
      font-size: 0.26rem;
      color: #111111;
  .area-right
    position absolute
    right 0.4rem
    .lanSwitch
      display inline-block
      vertical-align middle
      margin-right 0.18rem
      .el-dropdown-link
        font-size: 0.26rem;
        color: #1E1E20;
    .search-icon
      width 0.325rem
      height 0.325rem
      display inline-block
      vertical-align middle
      img
        width 100%
    .input-area
      position relative
      display inline-block
      vertical-align middle
      .input
        background #F3F3F3
        border-radius 0.12rem
        outline none
        font-family:Roboto-Regular;
        font-size 0.26rem
        width 2.28rem
        height 0.8rem
        line-height 0.3rem
        color #A0A0A0
        padding-left 0.53rem
      .input-search_icon
        width 0.24rem
        height 0.24rem
        position absolute
        top 0.28rem
        left 0.2025rem
.section
  background #F8FAFD
  min-height calc( 100vh - 1.44rem)
  overflow hidden
  .problem-details
    margin 0.4rem 0.4rem
    background #FFFFFF
    overflow hidden
    .problem
      border-bottom 0.01rem dashed #E8E8E8
      .point
        width 0.1rem
        height 0.1rem
        background #FF6B08
        border-radius 50%
        margin 0.47rem 0.08rem 0.47rem 0.4rem
        display inline-block
        vertical-align middle
      .title
        display inline-block
        font-family Roboto-Black
        font-size 0.34rem
        line-height 0.44rem
        color #FF6B08
        vertical-align middle
      .problem-content
        font-family:Roboto-Regular;
        margin 0 0.4rem 0.4rem
        font-size: 0.16rem
        color: #333333;
      .problem-content >>> img
        width 100%
    .answer
      .point
        width 0.1rem
        height 0.1rem
        background #FF6B08
        border-radius 50%
        margin 0.47rem 0.08rem 0.47rem 0.4rem
        display inline-block
        vertical-align middle
      .title
        display inline-block
        font-family Roboto-Black
        font-size 0.32rem
        line-height 0.44rem
        color #FF6B08
        vertical-align middle
      .answer-content
        margin 0 0.4rem 0.5rem
        color #000000
        .answer-head
          position relative
          .answer-text, .answer-result
            font-family Roboto-Bold
            font-size 0.20rem
            line-height 0.23rem
            display inline-block
            color #333333
            vertical-align middle
          .answer-result
            width 0.3rem
            height 0.3rem
            margin-left 0.15rem
            img
              width 100%
          .answer-by
            font-family Roboto
            font-size 0.14rem
            height 0.16rem
            line-height 0.16rem
            font-weight 500
            vertical-align middle
            color #999999
            margin auto
            position absolute
            top 0
            bottom 0
            right 0
            display inline-block
        .answer-analysis
          margin-top 0.47rem
          font-size 0.16rem
          background: #F8FAFD;
          border-radius: 8px;
          overflow hidden
          .analysis-text
            margin 0.4rem 0px 0.12rem 0.24rem
            font-family Roboto-Regular
            font-size 0.16rem
            line-height 125%
            color #333333
          .analysis-content
            margin 0rem 0.24rem 0.4rem
          .analysis-content >>> img
            max-width 100%
        .remove-mosaic
          width 4rem
          height 0.48rem
          background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
          border-radius 0.08rem
          font-size 0.21rem
          line-height 0.48rem
          color #FF6B08
          text-align center
          font-weight 500
          margin 0.32rem 0 0.4rem
  .problems-list
    margin 0 0.4rem 0.4rem
    background #FFFFFF
    overflow hidden
    .no-problems
      .no-porblems_img
        margin 0.8rem auto 0.32rem
        width 1.4rem
        height 1.06rem
        img
          width 100%
       .no-problems_text
        width 2rem
        margin 0 auto 0.9rem
        font-family Roboto-Regular
        font-size 0.18rem
        line-height 0.27rem
        letter-spacing 0.001rem
        color #111100
        text-align center
    .title
      margin 0.3rem 0 0.3rem 0.4rem
      height 0.44rem
      font-size 0.32rem
      line-height 0.44rem
      font-family Roboto-Black
      color #111100
    .cut-line
      height 1px
      background #DDDDDD
    .problems-content
      overflow hidden
      .problems-item
        margin 0.4rem 0.4rem 0
        border-bottom 0.01rem solid #E8E8E8
        .check-solution
          width 1.93rem
          height 0.48rem
          background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
          border-radius 0.08rem
          font-size 0.2rem
          line-height 0.48rem
          color #FF6B08
          text-align center
          font-weight 500
          margin 0.32rem 0 0.4rem
  .not-question
    margin 0 0.4rem 1.9rem
    overflow hidden
    background #FFFFFF
    box-shadow 0 0.03rem 0.3rem rgba(132, 145, 165, 0.15)
    border-radius 0.08rem
    .title
      height 0.33rem
      margin 0.62rem auto 0.24rem
      text-align center
      font-family Roboto-Bold
      font-size 0.28rem
      line-height 0.33rem
      color #333333
    .button-unlock_question
      margin 0 0.4rem 0.63rem
      background #FFFFFF
      border 0.02rem solid #FF6B08
      border-radius 0.08rem
      font-weight 700
      font-size 0.2rem
      line-height 0.48rem
      text-align center
      color #FF6B08
.footer
  overflow hidden
  width 100%
  height:1.3rem
  position fixed
  bottom 0
  background :url(../../assets/img/m/footerbg.png) 0 0 / 100%
  .logo
    width:0.6rem
    height:0.6rem
    display inline-block
    position absolute
    top 0.35rem
    left 0.6rem
    border:0.001rem soild #FFFFFF
    img
      width:100%
  .title
    width:3.8rem
    font-family:Roboto-Black;
    display inline-block
    font-weight: 900;
    font-size: 0.3rem;
    line-height: 0.35rem;
    text-align: left;
    color: #FFFFFF;
    position absolute
    top 0.33rem
    left 1.41rem
    span
      color:#FF6B08;
  .google
    width 1.66rem
    height 0.4919rem
    display inline-block
    position absolute
    top 0.4rem
    right 0.6rem
    img
      width:100%
</style>
<style lang="stylus">
.icon
  width 0.1rem
  height 0.1rem
  box-sizing border-box
  display inline-block
  background linear-gradient(0deg, rgba(255, 107, 8, 0.1), rgba(255, 107, 8, 0.1)), #FFFFFF
  border 0.02rem solid #FF6B08
  border-top 0
  border-left 0
  transform rotate(-45deg)
.mosaic
  width 0.15rem
  height 0.15rem
  display inline-block
  background url(../../assets/img/mosaic.png) 0 0 / 100% 100%
</style>
