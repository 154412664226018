<template lang="pug">
.try-more(v-if='isTryMore')
  .title(v-html="$TT('tryMore')")
  .desc
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-one')")
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-two')")
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-three')")
    .item
      img.img(src='../assets/img/point.png')
      span(v-html="$TT('descItem-four')")
  .google_img(@click="download('detailpage_dialog_download')")
    .google
      img(src="../assets/img/google.png")
  .try-later(@click="closeTryMore")
    span(v-html="$TT('Try it later')")
</template>
<script>
export default {
  data() {
    return {
      downloadUrl: 'https://answered.onelink.me/DJB9/a15710c3',
    }
  },
  computed: {
    // ...mapGetters(["isTryMore"]),
    isTryMore: {
      get() {
        return this.$store.state.isTryMore
      },
      set(newValue) {
        this.$store.state.isTryMore = newValue
      },
    },
  },
  watch: {
    "$store.state.isTryMore"(val) {
      // console.log(val, 'val');
      // this.isTryMore = false
      this.isTryMore = val
    }
  },
  methods: {
    download(eventType) {
      window.gtag('event', eventType)
      const a = document.createElement('a')
      a.href = this.downloadUrl
      a.click()
    },
    closeTryMore() {
      this.$store.commit('isTryMore', false)
      window.gtag('event', 'detailpage_dialog_cancel')
    }
  }
}
</script>
<style lang="stylus" scoped>
.try-more
  margin auto 1.05rem
  position absolute
  top 0
  bottom 0
  left 0
  right 0
  height 5.6rem
  background linear-gradient(139.74deg, #FFF5ED -12.64%, #FFFFFF 75.01%)
  box-shadow 0 0.03rem 0.3rem rgba(132, 145, 165, 0.15)
  border-radius 8px
  .title
    margin 0.4rem 0 0.32rem 0.56rem
    font-family:Roboto-Bold;
    font-size 0.48rem
    line-height 0.56rem
    color #333333
  .desc
    margin 0 0 0.73rem 0.56rem
    .item
      margin-bottom 0.25rem
      font-family Roboto-Regular
      font-size 0.2rem
      line-height 0.23rem
      color #666666
      .img
        width 0.15rem
        height 0.15rem
        display inline-block
        margin-right 0.08rem
  .google_img
    width 2.835rem
    height 0.84rem
    display inline-block
    vertical-align middle
    margin-left 0.56rem
    cursor pointer
    .google
      height 100%
    img
      width 100%
  .try-later
    font-family Roboto-Regular
    display inline-block
    vertical-align middle
    margin-left 0.36rem
    width 1.2rem
    font-size 0.225rem
    line-height 0.32rem
    color #666666
    cursor pointer
</style>